import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import Article from "components/Article";
import LinkButton from "components/buttons/LinkButton";
import Layout from "components/Layout";
import { graphql, navigate, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";

interface DataProps {
    mdx: {
        frontmatter: {
            title: string;
            description: string;
        };
        body: string;
    };
    site: {
        siteMetadata: {
            siteUrl: string;
        };
    };
}

interface ArticleLocationState {
    /** true if we're coming from the articles list page */
    fromIndex?: boolean;
}

const ArticleContent: React.FC<
    PageProps<DataProps, object, ArticleLocationState>
> = ({ path, location, data }) => {
    const { mdx, site } = data;
    const { frontmatter, body } = mdx;
    const { title, description } = frontmatter;

    const layoutProps = {
        path,
        title,
        description,
    };

    const backButtonProps = {
        didNavigateFromIndex: location.state?.fromIndex,
    };

    return (
        <Layout {...layoutProps}>
            <Article>
                <Article.Header>
                    <Article.Heading>{title}</Article.Heading>
                </Article.Header>

                <Article.BodyL>
                    <MDXRenderer>{body}</MDXRenderer>
                </Article.BodyL>

                <Article.Footer>
                    <div className="d-flex justify-content-center mt-4">
                        <BackButton {...backButtonProps} />
                    </div>
                </Article.Footer>
            </Article>
        </Layout>
    );
};

export default ArticleContent;

export const query = graphql`
    query ($id: String!, $previousId: String, $nextId: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                description
            }
            body
        }
        previous: mdx(id: { eq: $previousId }) {
            fields {
                slug
                sourceInstanceName
            }
            frontmatter {
                title
            }
        }
        next: mdx(id: { eq: $nextId }) {
            fields {
                slug
                sourceInstanceName
            }
            frontmatter {
                title
            }
        }
    }
`;

interface BackButtonProps {
    /** true if we're coming from the articles list page */
    didNavigateFromIndex?: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({ didNavigateFromIndex }) => {
    function handleClick() {
        // If we're coming here from the list of articles, use the history API
        // to go back one page. This allows the scroll location to be preserved.
        if (didNavigateFromIndex) {
            navigate(-1);
        } else {
            navigate("/articles/");
        }
    }

    return (
        <LinkButton.Secondary onClick={handleClick}>
            {/* Add extra trailing padding for better visual centering */}
            <div className="hstack gap-2 pe-2">
                <ArrowLeftIcon width={18} />
                Back to all articles
            </div>
        </LinkButton.Secondary>
    );
};
